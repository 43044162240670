.container {
  border-radius: 30px;
  background: #141631;
  padding: 34px 32px 32px 32px;
  gap: 24px;
  margin-top: 42px;
}

.availableButton {
  border-radius: 30px;
  background: rgba(126, 136, 187, 0.2);
  margin: auto 17px auto auto;
  height: 24px;
  padding: 0 8px;
  cursor: default;
}

.walletIcon {
  margin: auto 9px auto 0;
}

.price {
  margin: auto 4px auto 0;
}

.ruble {
  margin: auto 24px auto 0;
}

.getCoin {
  width: 176px;
  height: 44px;
  margin: auto 0 auto 0;
}

.main {
  gap: 24px;
}
