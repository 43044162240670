.section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  gap: 16px;
}

.headericon {
  width: 40px;
  height: 40px;
}

.headerText {
  font-size: 32px;
  font-weight: 500;
}

.articlesContainer {
  display: flex;
  gap: 32px;
  margin-bottom: 64px;
}

.articlesHeaderText {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 48px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.information {
  border-radius: 14px;
  background: #141631;
  padding: 32px;
  height: fit-content;
  width: 100%;
}

.informationHeader {
  display: flex;
  gap: 56px;
  align-items: center;
  margin-bottom: 48px;
}

.informationHeaderText {
  white-space: nowrap;
}

.loaderPage {
  display: flex;
  flex: 1;
}
