.modal {
  border-radius: 30px;
  background: #141631;
  padding: 48px;
  width: 640px;
}

.header {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.closeIcon {
  cursor: pointer;
}

.price.total {
  margin-top: 5px;
}

.ruble.total {
  margin-top: 5px;
}

.promoInput {
  margin-left: 2px;
  width: 195px;
}

.acceptCommonText {
  font-size: 14px;
  color: #7e88bb;
  margin-top: 2px;
}

.acceptTextContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.checkButtonStyle {
  margin: auto auto 0;
}

.submitButton {
  margin-top: 24px;
  height: 58px;
  background: #7b4cff;
}
