@import url('/src/fonts/fonts.css');

@import url('./styled/colors.css');

body {
  margin: 0;
  height: 100%;
  font-family:
    'Firs Neue',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #ffffff;
}

p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #ffffff;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  color: #ffffff;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

button {
  font-family:
    'Firs Neue',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  color: #ffffff;
}

input {
  font-family:
    'Firs Neue',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  color: #ffffff;
}

input::placeholder,
textarea::placeholder {
  font-family:
    'Firs Neue',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  color: #ffffff;
}

code {
  font-family: 'Firs Neue', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.link {
    text-decoration: none;
    color: inherit;
}
