.container {
  border-radius: 20px;
  border: 1px solid #333a70;
  display: flex;
  justify-content: space-between;
  width: 270px;
  align-items: center;
}

.input {
  margin: 12px auto 12px 18px;
  border: none;
  background: transparent;
  outline: none;
  font-size: 14px;
  font-weight: 500;
}

.icon {
  width: 20px;
  height: 20px;
  padding: 8px;
  border-radius: 14px;
  background: rgba(45, 53, 93, 0.4);
  margin-right: 8px;
  cursor: pointer;
}

.input::placeholder {
  color: #7e88bb;
  font-size: 14px;
  font-weight: 500;
}
