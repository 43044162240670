.priceBlock {
  margin-top: 8px;
  gap: 8px;
}

.dollar {
  margin-top: 6px;
}

.priceText {
  margin: auto 2px 4px auto;
}

.statusText {
  border-radius: 30px;
  background: var(--green-scarlet-color);
  align-items: center;
  height: 16px;
  padding: 0 8px;
  margin: 0 0 auto auto;
}

.statusText.error {
  background: rgba(248, 90, 90, 0.3);
  color: #f85a5a !important;
}

.disable {
  pointer-events: none;
  color: #7e88bb;
}
