.container {
  border-radius: 30px;
  margin-top: 43px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.inputContainer {
  padding: 7px 16px 7px 8px;
  border-radius: 20px;
  border: 1px solid #333a70;
  align-items: center;
  flex: 1;
  gap: 30px;
}

.input {
  background: transparent;
  border: none;
  flex: 1;
}

.input::placeholder {
  font-size: 16px;
  color: #7e88bb;
}

.linkButton {
  height: 56px;
  width: 250px;
  justify-content: start;
  padding-left: 25px;
  cursor: default;
}

.generateButton {
  height: 40px;
  padding: 0 8px;
  gap: 4px;
}

.createLinkButton {
  background: #7b4cff;
  width: 155px;
  height: 56px;
}

.createLinkButton.disable {
  background: var(--grey-color);
  cursor: no-drop;
}
