.container {
  position: absolute;
  visibility: hidden;
  background-color: #262357;
  border-radius: 14px;
  padding: 15px;
  top: 60px;
  left: 0;
  z-index: 1;
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 244px;
}

.container.visible {
  visibility: visible;
}
