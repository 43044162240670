.section {
  margin-top: 154px;
  height: 532px;
  position: relative;
}

.button {
  position: absolute;
  width: 233px;
  right: 28px;
  bottom: 37px;
  z-index: 1;
}

.yarn {
  position: absolute;
  top: 148px;
  left: 277px;
}
