.card {
  border-radius: 14px;
  background: #2a3152;
  width: 173px;
  gap: 22px;
  cursor: pointer;
  width: 173px;
  height: 96px;
  justify-content: center;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.card.active {
  background: #28255c;
}

.header {
  align-items: center;
  gap: 8px;
}

.circle {
  background: #7b4cff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.delete {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: auto 0 auto auto;
}

.phoneBlock {
  gap: 4px;
  align-items: center;
}

.deleteBlock {
  gap: 10px;
  align-items: center;
}

.buttonsBlock {
  gap: 8px;
  width: 100%;
}

.button {
  border-radius: 8px;
  border: none;
  font-size: 14px;
  flex: 1;
  height: 32px;
  cursor: pointer;
}

.button.delete {
  color: #f85a5a;
  background: rgba(248, 90, 90, 0.24);
}

.button.cancel {
  color: #ffffff;
  background: #7b4cff;
}
