.card {
  border-radius: 30px;
  background: var(--purple-very-dark-color);
  padding: 20px 32px 20px 20px;
  min-width: 780px;
  gap: 24px;
}

.photo {
  width: 228px;
  height: 228px;
  border-radius: 14px;
  object-fit: contain;
}

.textBlock {
  flex: 1;
}

.informationBlock {
  gap: 16px;
  width: 100%;
}

.divider {
  margin: 12px auto 0;
  width: calc(100% - 16px);
}
