.section {
  padding: 48px;
  background: var(--purple-very-dark-color);
  border-radius: 30px;
  margin-bottom: 100px;
  position: relative;
}

.mainTextBlock {
  margin-top: 24px;
  justify-content: space-between;
}

.mainData {
  margin-top: 24px;
  gap: 32px;
}

.mainInfo {
  width: 552px;
}

.copyButton {
  height: 18px;
}

.gallery {
  margin-top: 16px;
}

.chartBlock {
  margin-top: 34px;
}

.dataList {
  margin-top: 24px;
}
