.container {
  position: relative;
  padding: 2px;
  border: 1px solid #504cff;
  border-radius: 16px;
  cursor: pointer;
  width: fit-content;
}

.badgeBlock {
  position: absolute;
  border-radius: 30px;
  background: #504cff;
  gap: 8px;
  padding: 4px 8px;
  align-items: center;
  top: -12px;
  right: 10px;
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.childrenBlock {
  gap: 15px;
  border-radius: 14px;
  background: #1e1c45;
  height: 43px;
  align-items: center;
  padding: 0 12px;
}

.lock {
  width: 20px;
  height: 20px;
}

.childrenText {
  color: #7e88bb;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
