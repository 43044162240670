.footer {
  background: #141631;
  width: 100%;
  padding: 32px 0;
  z-index: 1;
  margin: auto auto 0;
}

.container {
  width: 1120px;
  margin: auto;
  display: flex;
  gap: 27px;
  flex-direction: column;
}

.section {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.copyrightBlock {
  display: flex;
  align-items: center;
  gap: 24px;
}

.pagesBlock {
  display: flex;
  align-items: center;
  gap: 40px;
}

.social {
  display: flex;
  gap: 16px;
}

.copyrightText {
  font-size: 14px;
  color: #7e88bb;
  margin: 0 auto auto;
}

.buttonsBlock {
  display: flex;
  gap: 21px;
  align-items: center;
}

.supportText {
  font-size: 14px;
}

.developIt {
  color: #a863ff;
  text-decoration: none;
}
