.section {
  position: relative;
}

.content {
  z-index: 2;
}

.back {
  top: -110px;
  left: 18px;
}
