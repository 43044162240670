.whatBlock {
  gap: 16px;
  align-items: center;
}

.whatDivider {
  width: 100%;
  height: 1px;
  background: #333a70;
}
