.section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: unset;
}

.container {
  padding: 40px 24px 24px 24px;
  border-radius: 20px;
  background: var(--drown-color);
  width: 496px;
  align-items: center;
}

.container.mobile {
  padding: 40px 24px 24px 24px;
  border-radius: 20px;
  background: var(--drown-color);
  width: 280px;
  align-items: center;
}

.img {
  width: 32px;
  height: 32px;
}

.header {
  margin-top: 42px;
}

.text {
  text-align: center;
  margin-top: 2px;
  line-height: 24px;
}

.button {
  border-radius: 14px;
  background: var(--drown-scarlet-color);
  color: var(--drown-color) !important;
  margin-top: 39px;
  height: 48px;
  width: 137px;
}
