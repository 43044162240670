.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkmark {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #ffffff;
}

.checkbox input[type='checkbox'] {
  display: none;
}

.checkbox input[type='checkbox']:checked + .checkmark {
  background-color: #7b4cff;
  background-image: url('../../../icons/check-mark_white.svg');
  background-repeat: no-repeat;
  background-position: center;
}
