.header {
  align-items: center;
  gap: 40px;
}

.divider {
  flex: 1;
}

.button {
  border-radius: 14px;
  background: #2d355d;
  padding: 0 12px;
  height: 24px;
}

.propertyesBlock {
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}

.property {
  justify-content: space-between;
  align-items: center;
}
