.companionData {
  gap: 16px;
  align-items: center;
}

.companionAvatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid #7b4cff;
}

.companionAvatar.disable {
  width: 40px;
  height: 40px;
  border: none;
}

.companionTextBlock {
  gap: 4px;
}
