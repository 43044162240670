.container {
  border-radius: 16px;
  padding: 0 12px;
  height: 48px;
  background-color: #262357;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
