.container {
  width: 249px;
  border-radius: 24px;
  background: #1a1d3b;
  padding: 10px 20px 18px;
  display: flex;
  flex-direction: column;
}

.infoSection {
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 15px;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 11px;
}

.infoName {
  color: #7e88bb;
  font-size: 14px;
  font-weight: 500;
}

.infoText {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
