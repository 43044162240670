.tradeBox {
  margin-top: 24px;
  gap: 16px;
  border-radius: 16px;
  background: #1e1c45;
  padding: 16px;
}

.containerDisable {
  background: #1a1d3c;
}

.dividerBlock {
  gap: 32px;
  align-items: center;
}

.traderInfoBlock {
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--purple-color);
}

.infoButtonsBox {
  gap: 16px;
  flex: 1;
}

.button {
  flex: 1;
  height: 48px;
}

.button.call {
  background: #273155;
}

.button.chat {
  background: #293246;
}

.textDisable {
  pointer-events: none;
  color: #7e88bb;
}

.bgDisable {
  background: var(--grey-color) !important;
  pointer-events: none;
}

.iconDisable {
  filter: sepia(100%) hue-rotate(184deg) brightness(80%);
}

.userStatusText.offline {
  color: #f85a5a !important;
}
.userStatusText.online {
  color: #ffc700 !important;
}
.userStatusText.game {
  color: #8ef84f !important;
}

.copyPhoneButton {
  background: var(--green-dark-color);
  border-radius: 14px;
  gap: 8px;
  padding: 0 12px;
  align-items: center;
  height: 48px;
  flex: 1;
  justify-content: center;
}

.copyIcon {
  width: 16px;
  height: 16px;
}
