.container {
  padding: 12px 16px;
  border-radius: 14px;
  border: 1px solid #3b4267;
  display: flex;
  justify-content: center;
  gap: 8px;
  background: rgba(123, 76, 255, 0.1);
  align-items: center;
}

.container.invalid {
  background-color: #000000;
}

.container:focus-within {
  border-color: #7b4cff;
  box-shadow: 0 0 5px #7b4cff;
}

.container.invalid {
  border-color: #f85a5a;
  box-shadow: 0 0 7px #532d2d;
}

.input {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
}

.input::placeholder {
  color: #7e88bb;
}

.bottomText {
  font-size: 14px;
  height: 40px;
  color: #7e88bb;
  margin-top: 9px;
}

.bottomText.valid {
  color: #7e88bb;
}

.bottomText.invalid {
  color: #f85a5a;
}
