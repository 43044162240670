.container {
  border-radius: 0px 0px 16px 16px;
  background: #1a1d3c;
  gap: 16px;
  padding: 28px 24px;
  align-items: center;
}

.input {
  background: transparent;
  border: 1px solid #7e88bb;
  border-radius: 14px;
  padding: 12px;
  flex: 1;
}

.input::placeholder {
  color: #7e88bb;
}

.button {
  border: 12px;
  background: rgba(123, 76, 255, 0.2);
  width: 32px;
  height: 32px;
}
