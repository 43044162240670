.alert {
  border-radius: 20px;
  border: 1px solid #1a1d3c;
  gap: 8px;
  padding: 24px;
  align-items: center;
}

.ballIcon {
  width: 24px;
  height: 24px;
}
