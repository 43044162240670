.footer {
  background: #141631;
  width: 100%;
  padding: 22px 0 40px;
  margin-top: 40px;
  z-index: 1;
}

.container {
  width: 328px;
  margin: 0 auto;
}

.buttonsBlock {
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.supportText {
  margin-top: 21px;
}

.supportButton {
  margin-top: 18px;
  max-width: 180px;
}

.social {
  gap: 16px;
  margin-top: 26px;
}

.copyrightText {
  margin-top: 19px;
}

.linksContainer {
  margin-top: 20px;
  gap: 23px;
}
