.container {
  display: flex;
  position: relative;
  width: 31px;
  height: 31px;
}

.avatar {
  border: 2px solid #7b4cff;
  border-radius: 60px;
}

.active {
  border: 2px solid #62ffea;
}

.status {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.offline {
  background-color: #f85a5a;
}

.online {
  background-color: #ffc700;
}

.game {
  background-color: #8ef84f;
}
