.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: #0e0e26;
  z-index: 1;
}

.welcomeBackground {
  background-image: linear-gradient(180deg, rgba(5, 5, 31, 0.88) 82%, #0e0e26 100%),
    url('../../images/welcome-bg-image.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 746px;
}

.catalogBackground {
  margin-top: 880px;
  background-image: linear-gradient(180deg, rgba(10, 9, 32, 0.3) 0%, #0e0e26),
    linear-gradient(0deg, rgba(10, 9, 32, 0.3) 0%, #0e0e26), url('../../images/catalog-bg-image.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 746px;
}

.filtersBackground {
  margin-top: 880px;
  background-image: linear-gradient(180deg, rgba(10, 9, 32, 0.3) 0%, #0e0e26),
    linear-gradient(0deg, rgba(10, 9, 32, 0.3) 0%, #0e0e26), url('../../images/filters-bg-image.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 746px;
}

.notificationsBackground {
  margin-top: 880px;
  background-image: linear-gradient(180deg, rgba(10, 9, 32, 0.3) 0%, #0e0e26),
    linear-gradient(0deg, rgba(10, 9, 32, 0.3) 0%, #0e0e26), url('../../images/notifications-bg-image.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 746px;
}

.activityesBackground {
  margin-top: 880px;
  background-image: linear-gradient(180deg, rgba(10, 9, 32, 0.3) 0%, #0e0e26),
    linear-gradient(0deg, rgba(10, 9, 32, 0.3) 0%, #0e0e26), url('../../images/activitys-bg-image.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 746px;
}
