.container {
  margin-top: 124px;
  position: relative;
  display: flex;
}

.carSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 60px;
  z-index: 2;
  pointer-events: none;
}

.textSection {
  margin: 177px 43px auto auto;
  z-index: 1;
}

.number {
  text-align: end;
}

.headerText {
  text-align: center;
  margin-top: 22px;
  margin-left: 93px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin: 38px auto auto 298px;
}

.listItem {
  display: flex;
  gap: 16px;
}

.listElipse {
  width: 8px;
  height: 8px;
  background: #7b4cff;
  border-radius: 50%;
  flex-shrink: 0;
}

.button {
  right: 30px;
  bottom: -61px;
  position: absolute;
  width: 232px;
  z-index: 1;
}
