.backButton {
  border-radius: 14px;
  background: #2a3152;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.backButtonIcon {
  width: 24px;
  height: 24px;
}
