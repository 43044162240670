.container {
  border-radius: 14px;
  border: none;
  padding: 0 25px 0 21px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.container.disable {
  background: var(--purple-dark-color);
}

.text {
  font-weight: 500;
}

.count {
  font-weight: 500;
  width: 60px;
  text-align: end;
  margin: auto 14px auto auto;
}

.arrow {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 0.5px;
  height: 0.5px;
}

.vipDisable {
  border: 2px solid var(--blue-color);
  border-radius: 16px;
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
}

.lockedIcon {
  margin-right: 4px;
  width: 16px;
  height: 15px;
}

.disableMessage {
  top: -18px;
  right: 6px;
}

.diableMessageIcon {
  width: 16px;
  height: 16px;
}
