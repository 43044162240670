.button {
  height: 41px;
  border: none;
  background: #7b4cff;
  border-radius: 14px;
  cursor: pointer;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
