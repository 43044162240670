.container {
  border-radius: 20px;
  border: 1px solid #1a1d3c;
  padding: 24px;
  gap: 16px;
}

.textContainer {
  gap: 8px;
}

.button {
  width: 89px;
  background: var(--grey-dark-color);
  pointer-events: none;
  cursor: default;
}
