.underline {
  margin-top: 8px;
  background: transparent;
  height: 1px;
  width: 100%;
}

.underline.active {
  background: #7b4cff;
}

.text {
  padding: 0 16px;
}

.text.active {
  color: #7b4cff;
}
