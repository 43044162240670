.container {
  padding: 16px;
  border-radius: 0px 16px 16px 16px;
  background: #2a3152;
  gap: 10px;
  max-width: 90%;
  width: fit-content;
  margin: 0 auto 0 0;
}

.container.user {
  background: #241a51;
  border-radius: 16px 0 16px 16px;
  margin: 0 0 0 auto;
}

.header {
  gap: 8px;
}

.userData {
  gap: 4px;
}

.userNameStatus {
  margin-left: 4px;
}

.whiteSpace {
    white-space: pre-wrap;
}
