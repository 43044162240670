.lockButton {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  padding: 0 11px;
  cursor: pointer;
}

.lockButtonIcon {
  margin-right: 7px;
}

.lockButtonText {
  color: #7e88bb;
  font-size: 14px;
  font-weight: 500;
}

.lockButtonBage {
  margin-left: 9px;
}
