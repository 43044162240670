.container {
  width: 290px;
  position: absolute;
  right: 65px;
  top: 43px;
  z-index: 2;
  pointer-events: none;
}

.checkContainer {
  padding: 24px;
}

.headerText {
  line-height: 24px;
}

.checkbox {
  border-radius: 14px;
  padding: 12px 16px;
  gap: 8px;
  width: 209px;
}

.checkbox.disable {
  border: 1px solid #7e88bb;
  margin-top: 38px;
}

.checkbox.active {
  background: #7b4cff;
  margin-top: 18px;
}

.itemFilter {
  border-radius: 14px;
  background: #2a3151;
  box-shadow: 0px 48px 32px 0px rgba(14, 14, 38, 0.24);
  padding: 14px 25px 14px 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.itemArrow {
  width: 8.48px;
  height: 5.65px;
}

.item {
  border-radius: 10px;
  padding: 13px 28px 14px 16px;
}

.item.active {
  background: #262357;
}

.scrollContainer {
  border-radius: 14px;
  background: #2a3151;
  box-shadow: 0px 48px 32px 0px rgba(14, 14, 38, 0.24);
  padding: 17px 16px 13px 12px;
  gap: 16px;
  margin-top: 16px;
  justify-content: space-between;
}

.sctollImg {
  width: 4px;
  height: 192px;
}
