.card {
  border-radius: 14px;
  background: rgba(123, 76, 255, 0.2);
  width: 160px;
  height: 80px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;
  width: 213px;
}

.icon {
  width: 20px;
  height: 20px;
}
