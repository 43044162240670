.container {
  padding: 3px 8px;
  border-radius: 10px;
  border: 1px solid #7b4cff;
  display: flex;
  justify-content: center;
  gap: 8px;
  background: rgba(123, 76, 255, 0.1);
}

.container.invalid {
  background-color: #000000;
}

.container:focus-within {
  border-color: #7b4cff;
  box-shadow: 0 0 5px #7b4cff;
}

.container.invalid {
  border-color: #f85a5a;
  box-shadow: 0 0 7px #532d2d;
}

.input {
  width: 100%;
  height: 14px;
  background: transparent;
  border: none;
  outline: none;
}

.input::placeholder {
  color: #7e88bb;
}

.icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
