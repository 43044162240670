.container {
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-decoration: none;
  padding: 0;
}

.icon {
  flex-shrink: 0;
}

.text {
  flex-shrink: 0;
  font-size: 14px;
}
