.card {
  border-radius: 14px;
  background: #2a3152;
  padding: 27px 24px 18px 24px;
  width: 288px;
  gap: 8px;
}

.cardSub {
  margin: auto auto 0 8px;
}

.cardIcon {
  width: 32px;
  height: 32px;
  margin: auto 0 4px;
}
