.footer {
  background: #141631;
  width: 100%;
  padding: 20px 0 32px;
  z-index: 1;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  margin-left: 16px;
}

.copyrightBlock {
  display: flex;
  align-items: center;
  gap: 24px;
}

.social {
  display: flex;
  gap: 16px;
}

.copyrightText {
  font-size: 14px;
  color: #7e88bb;
}

.supportText {
  font-size: 14px;
}
