.container {
  position: relative;
  border-radius: 14px;
  padding: 0 12px;
  height: 40px;
  background-color: #262357;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.icon {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
}

.text {
  flex-shrink: 0;
  font-size: 14px;
}
