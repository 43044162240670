.section {
  position: relative;
}

.cardImage {
  margin: 0 auto;
  z-index: 2;
  width: 328px;
  height: 407px;
}

.sectionNumber {
  position: absolute;
  font-size: 23px;
  z-index: 2;
}

.listHeader {
  font-size: 30px;
  margin: 8px auto 0 44px;
  color: var(--grey-scarlet-color);
  z-index: 2;
}

.itemsList {
  margin: 22px auto 0 45px;
  display: flex;
  flex-direction: column;
  gap: 29px;
  z-index: 2;
}

.item {
  display: flex;
  gap: 15px;
  width: 262px;
  line-height: 16px;
  z-index: 2;
}

.button {
  margin: 54px auto 0;
  width: 296px;
  height: 60px;
  z-index: 2;
}

.background {
  position: absolute;
  background: #131131;
  height: 450px;
  width: calc(100% - 32px);
  margin: 0 16px;
  border-radius: 40px;
  z-index: 1;
  bottom: 83px;
}
