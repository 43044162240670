.section {
  margin: 0 auto;
  align-items: start;
  width: 289px;
}

.header {
  width: 286px;
}

.subscription {
  margin-top: 1px;
  width: 270px;
  color: var(--purple-color) !important;
}

.filtersContainer {
  margin-top: 65px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 300px;
}
