.container {
  flex: 1;
}

.priceBlock {
  gap: 10px;
}

.price {
  line-height: 42px;
  margin-top: 13px;
}

.dollar {
  margin-top: 3px;
}

.priceText {
  text-align: end;
}

.noPriceText {
  margin-top: 16px;
  margin-bottom: 18px;
}

.buyButtonContainer {
  margin-top: 56px !important;
  gap: 22px;
  align-items: center;
}
