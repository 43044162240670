.card {
  border-radius: 14px;
  background: #2a3152;
  padding: 0 12px;
  height: 60px;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.card:hover {
  background: #19153b;
}

.nameBlock {
  width: 259px;
  gap: 8px;
}

.chainIcon {
  width: 20px;
  height: 20px;
  margin-top: 3px;
}

.name {
  border-radius: 30px;
  background: rgba(126, 136, 187, 0.2);
  padding: 4px 8px;
}

.textBlock {
  width: 212px;
  align-items: center;
  justify-content: center;
}

.buttonsBlock {
  margin: auto 0 auto auto;
  gap: 2px;
}

.copyButton {
  margin-top: 2px;
}

.trashIcon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}
