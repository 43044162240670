.container {
  border-radius: 30px;
  background: #f85a5a;
  padding: 0 6px;
  position: absolute;
  top: 0;
  right: 0;
}

.text {
  font-size: 10px;
}
