.section {
  padding: 48px;
  background: var(--purple-very-dark-color);
  border-radius: 30px;
  margin-bottom: 100px;
}

.main {
  margin-top: 25px;
  gap: 32px;
}

.gallery {
  margin-top: 43px;
}

.chartBlock {
  margin-top: 34px;
}
