.button {
  width: 322px;
  background-color: #5869eb;
  border-radius: 14px;
  padding: 16px 20px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 8px;
}

.text {
  flex: 1;
  padding: 0;
}

.icon {
  width: 21.59px;
  height: 15.93px;
}
