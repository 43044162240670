.button {
  width: 104px;
  height: 40px;
  background: var(--purple-dark-color);
  border-radius: 12px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
}

.img {
  width: 24px;
  height: 24px;
  margin: auto 8px auto 8px;
}
