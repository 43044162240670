.priceBlock {
  margin-top: 19px;
}

.priceString {
  height: 32px;
  gap: 8px;
}

.priceText {
  margin: auto 0;
}

.priceTextDiscount {
  margin: auto auto auto 0;
  color: #cffb53 !important;
}

.price {
  margin: 3px 0 auto auto;
}

.ruble {
  margin-top: 3px;
}

.priceText.total {
  margin-top: 14px;
}
