.container {
  display: flex;
  gap: 14px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.text {
  color: #7e88bb;
  font-style: normal;
  font-weight: 500;
  user-select: none;
}

.text.active {
  color: #ffffff;
}
