.messageBlock {
  border-radius: 14px;
  padding: 8px 16px;
  background: #141631;
  gap: 5px;
}

.messageBlock.hover {
  background: #222649;
}

.message {
  gap: 8px;
  align-items: center;
}

.circle {
  width: 8px;
  height: 8px;
  background: #7b4cff;
  border-radius: 50%;
  box-shadow: 0px 0px 16px #7b4cff;
}

.messageText {
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  max-width: 740px;
  text-overflow: ellipsis;
}
