.modal {
  border-radius: 30px;
  background: #141631;
  padding: 48px;
  width: 638px;
}

.header {
  gap: 24px;
  align-items: center;
}

.headerText {
  margin-top: 4px;
}

.headerCloseIcon {
  margin: auto 0 auto auto;
  cursor: pointer;
}

.subText {
  margin-top: 29px;
  margin-bottom: 5px;
}

.refreshButton {
  width: 206px;
  height: 48px;
  margin: 24px auto 0;
  background: #7b4cff;
}
