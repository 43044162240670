.section {
  gap: 25px;
  position: absolute;
  right: 63px;
  top: -28px;
}

.container {
  gap: 10px;
  padding: 26px 24px;
  width: 209px;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  margin-left: 5px;
}

.headerText {
  margin-top: 1px;
  gap: 2px;
}

.icon {
  margin: 1px 4px auto auto;
}

.divider {
  background: #333a70;
}

.text {
  line-height: 24px;
  margin-left: 3px;
}
