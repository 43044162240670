.photoSlider {
  gap: 16px;
  align-items: center;
}

.photoBlock {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
}

.photo {
  width: 64px;
  height: 64px;
  border-radius: 12px;
  object-fit: contain;
}

.imageContainer {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
