.container {
  position: absolute;
  right: 290px;
  top: 0;
  z-index: 2;
  pointer-events: none;
}

.photo {
  border-radius: 14px;
  width: 249px;
  height: 252px;
  object-fit: cover;
}

.tradeStatus {
  margin-top: 24px;
}

.priceContainer {
  gap: 10px;
  margin-top: 11px;
}

.userContainer {
  gap: 12px;
  margin-top: 14px;
}

.itemName {
  margin-top: 15px;
}

.itemType {
  margin-top: 4px;
}

.divider {
  width: 220px;
  margin-top: 17px;
}

.button {
  margin-top: 15px;
}
