.container {
  margin-top: 15px;
  align-items: center;
  gap: 20px;
}

.filtersBlock {
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cancelFiltersButton {
  height: fit-content;
  padding: 0;
  margin: auto 0 auto auto;
}

.cancelFiltersButton.hidden {
  visibility: hidden;
}

.countText {
  margin-right: auto;
  margin-left: 25px;
}

.typeDropdown {
  width: 120px !important;
}

.typeSection {
  padding: 0 !important;
  overflow-y: unset !important;
}

.dropdown {
  width: 168px !important;
}
