.container {
  border-radius: 14px;
  background: #2a3152;
  padding: 16px;
  gap: 16px;
  position: relative;
}

.activeStatus {
  gap: 8px;
  align-items: center;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #8ef84f;
}

.errorStatus {
  gap: 8px;
  align-items: center;
}

.body {
  align-items: center;
}

.body > * {
  margin-right: 12px;
}

.body > *:last-child {
  margin: auto 0 auto auto;
}

.socialIcon {
  width: 40px;
  height: 40px;
}

.clipButton {
  padding: 0 8px;
  height: 28px;
  background: rgba(126, 136, 187, 0.1);
}

.divider {
  background: #333a70;
  height: 1px;
  width: 100%;
}

.footer {
  gap: 16px;
}

.footerIcon {
  margin: auto 0 auto auto;
  width: 20px;
  height: 20px;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  justify-content: space-between;
  background: #2a3152;
  border-radius: 14px;
}

.modalData {
  gap: 12px;
  align-items: center;
}

.modalIcon {
  width: 40px;
  height: 40px;
}

.modalButtonsBlock {
  gap: 8px;
}

.yesButton {
  width: 82px;
  height: 32px;
  padding: 0 !important;
  border-radius: 8px;
  background: rgba(248, 90, 90, 0.24);
}

.noButton {
  width: 82px;
  height: 32px;
  padding: 0 !important;
  border-radius: 8px;
  background: #7b4cff;
}
