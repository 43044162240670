.button {
  border-radius: 14px;
  min-width: 224px;
  height: 48px;
  padding: 0 16px;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.button.active {
  background: var(--purple-very-dark-color);
}

.icon {
  margin: auto 16px auto 0;
}

.arrow {
  margin: auto 0 auto auto;
}

.secondLevel {
  margin-top: 12px;
  margin-left: 28px;
  padding-left: 28px;
  gap: 20px;
  border-left: 2px solid #464b70;
}
