.card {
  border-radius: 30px;
  background: #141631;
  padding: 16px;
  position: relative;
}

.photoBlock {
  width: 120px;
  height: 120px;
  border-radius: 14px;
  position: relative;
}

.photo {
  border-radius: 14px;
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.photoFilter {
  position: absolute;
  opacity: 0.76;
  background: #000;
  width: 120px;
  height: 120px;
  border-radius: 14px;
  left: 0;
  top: 0;
}

.body {
  padding: 16px;
  justify-content: space-between;
  flex: 1;
  gap: 16px;
}

.headerBlock {
  gap: 16px;
}

.cardNameBlock {
  max-width: 270px;
  gap: 5px;
}

.cardName {
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.cardName.disable {
  color: #7e88bb;
}

.priceBlock {
  gap: 4px;
  padding: 12px 16px;
  border-radius: 14px;
  background: rgba(207, 251, 83, 0.1);
  height: 24px;
}

.priceBlock.disable {
  background: #1f223f;
}

.price {
  color: #8ef84f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.price.disable {
  color: #7e88bb;
}

.dolar {
  color: #8ef84f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dolar.disable {
  color: #7e88bb;
}

.count {
  padding: 12px 16px;
  border-radius: 14px;
  background: #1f223f;
  height: 24px;
}

.server {
  padding: 0 16px;
  width: fit-content;
  height: 24px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: #241a51;
}

.server.disable {
  background: rgba(126, 136, 187, 0.1);
}

.serverCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #7b4cff;
}

.serverCircle.disable {
  background: #7e88bb;
}

.serverName {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.serverName.disable {
  color: #7e88bb;
}
