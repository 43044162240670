.status {
  gap: 8px;
  align-items: center;
  background: rgba(142, 248, 79, 0.1);
  border-radius: 30px;
  height: 20px;
  padding: 0 8px;
  margin: 0 0 auto auto;
}

.status.hidden {
  background: rgba(126, 136, 187, 0.1);
}

.status.deleted {
  background: rgba(248, 90, 90, 0.1);
}

.status.moderation {
  background: rgba(248, 213, 90, 0.1);
}

.status.cancel {
  background: rgba(248, 90, 90, 0.1);
}

.status.banned {
  background: rgba(248, 90, 90, 0.1);
}

.status.overdue {
  background: rgba(255, 150, 91, 0.1);
}

.statusCircle {
  background: #8ef84f;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.statusCircle.hidden {
  background: #7e88bb;
}

.statusCircle.deleted {
  background: #f85a5a;
}

.statusCircle.moderation {
  background: #f8d55a;
}

.statusCircle.cancel {
  background: #f85a5a;
}

.statusCircle.banned {
  background: #f85a5a;
}

.statusCircle.overdue {
  background: #ff965b;
}

.statusText {
  color: #8ef84f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.statusText.hidden {
  color: #7e88bb;
}

.statusText.deleted {
  color: #f85a5a;
}

.statusText.moderation {
  color: #f8d55a;
}

.statusText.cancel {
  color: #f85a5a;
}

.statusText.banned {
  color: #f85a5a;
}

.statusText.overdue {
  color: #ff965b;
}

.activeStatusBlock {
  gap: 6px;
  align-items: center;
}
