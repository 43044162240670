.container {
  padding: 0 64px;
  display: flex;
  gap: 96px;
  z-index: 2;
  position: relative;
}

.textContainer {
  width: 416px;
  gap: 28px;
  margin-top: 59px;
  z-index: 2;
}

.headerText {
  line-height: 72px;
}

.subText {
  line-height: 32px;
}

.imageContainer {
  margin-top: 44px;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.contactBlock {
  position: absolute;
  top: -13px;
  left: 222px;
  gap: 24px;
  z-index: 2;
}

.contactContainer {
  padding: 21px !important;
}

.span {
  position: relative;
  bottom: 3px;
}

.button {
  width: 306px;
  margin-top: 7px;
  font-size: 14px;
  height: 60px;
}
