.header {
  margin: 34px auto 0;
  align-items: center;
  width: 294px;
  z-index: 2;
  display: flex;
}

.headerLogo {
  width: 52px;
  height: 56px;
}

.catalogButton {
  background: #343641;
  padding: 0 7px;
  margin-left: 43px;
  height: 30px;
  border-radius: 8px;
}

.advertismentButton {
  background: var(--azure-scarlet-color);
  padding: 0 7px;
  margin-left: 21px;
  height: 30px;
  border-radius: 8px;
}
