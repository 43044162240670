.container {
  min-width: 150px;
  padding: 12px 14px;
}

.container.blocked {
  padding: 12px 14px;
  border-radius: 14px;
  background: #262357;
}

.headerText {
  color: #7e88bb;
  font-style: normal;
  font-weight: 500;
  border-radius: 30px;
  background: rgba(126, 136, 187, 0.1);
  padding: 4px 8px;
  width: fit-content;
  margin-bottom: 20px;
}

.list {
  display: grid !important;
  grid-gap: 24px;
}

.list.column {
  display: flex !important;
  flex-direction: column;
}
