.container {
  border-radius: 30px;
  background: #1a1d3c;
  padding: 24px;
  gap: 23px;
}

.userBlock {
  gap: 16px;
  width: 317px;
}

.avatarBlock {
  gap: 16px;
}

.avatarText {
  gap: 6px;
  margin-top: 24px;
}

.inActiveVipAvatarText {
  margin-top: 13px;
}

.buyGifButton {
  border-radius: 30px;
  background: #504cff;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
  cursor: pointer;
}

.buyGifIcon {
  width: 16px;
  height: 16px;
}

.userInfo {
  border-radius: 14px;
  background: #28255c;
  padding: 15px 20px;
  gap: 12px;
}

.userInfoIcon {
  width: 16px;
  width: 16px;
}

.divider {
  background: #333a70;
  margin: 8px auto;
}

.activeSubBlock {
  background-image: url('./back.png');
  width: 281px;
  height: 182px;
  padding: 18px 20px 16px 20px;
  border-radius: 14px;
  background-size: 100%;
}

.statusBlock {
  gap: 8px;
  align-items: center;
  margin-top: 10px;
}

.status {
  gap: 8px;
  padding: 0 8px;
  height: 20px;
  border-radius: 30px;
  align-items: center;
  background: rgba(142, 248, 79, 0.1);
}

.statusIcon {
  width: 8px;
  height: 8px;
  background: #8ef84f;
  border-radius: 50%;
}

.button {
  border-radius: 8px;
  background: #504cff;
  width: fit-content;
  height: 40px;
  padding: 0 12px;
  margin: auto 0 2px auto;
}

.inActiveSubBlock {
  background-image: url('./inActiveBack.png');
  background-repeat: no-repeat;
  padding: 27px 22px 22px 22px;
  border-radius: 20px;
  background-size: 100%;
  border: 2px solid #504cff;
  width: 286px;
}

.inActiveSubText {
  letter-spacing: 0.3px;
}

.buyVipButton {
  border-radius: 8px;
  background: #504cff;
  padding: 12px;
  gap: 8px;
  margin-top: 12px;
  cursor: pointer;
}

.vipBadge {
  width: 16px;
  height: 16px;
}

.bold {
  font-weight: 700;
}

.list {
  gap: 8px;
  margin-top: 16px;
}

.listItem {
  gap: 8px;
}

.iconListItem {
  width: 20px;
  height: 20px;
}
