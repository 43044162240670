.promoBlock {
  margin-top: 25px;
}

.promoInputBlock {
  margin-top: 19px;
  gap: 12px;
}

.promoCodeInputContainer {
  padding: 15px 16px !important;
}

.promoButton {
  height: 50px !important;
  width: 122px;
}
