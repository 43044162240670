.container {
  position: relative;
  border-radius: 70px;
  border: 3px solid #7b4cff;
  width: 83px;
  height: 83px;
}

.vip {
  border: 3px solid #62ffea;
}

.avatar {
  border-radius: 50%;
  width: 83px;
  height: 83px;
}

.edit {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.delete {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: -3px;
  right: -2px;
  cursor: pointer;
}
