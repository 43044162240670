.headerText {
  margin-left: 7px;
}

.body {
  border-radius: 30px;
  background: #141631;
  padding: 24px;
}

.bodyDivider {
  margin: 39px 8px 42px;
  width: 100%;
  height: 1px;
  background: #333a70;
}
