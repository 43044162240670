.underlinedText {
  position: relative;
  cursor: pointer;
  text-align: center;
}

.backButtonText {
  position: relative;
  display: inline-block;
  font-size: 14px;
}

.backButtonText:before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}
