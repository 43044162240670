.container {
  padding: 24px;
  border-radius: 20px;
  border: 1px solid #1a1d3c;
  gap: 16px;
}

.textBlock {
  gap: 8px;
}

.button {
  width: fit-content;
  padding: 0 16px;
}
