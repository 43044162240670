.button {
  padding: 0 16px;
  height: 52px;
  background: var(--grey-color);
  border: none;
  border-radius: 14px;
  cursor: no-drop;
}

.button.active {
  background: var(--purple-color);
  cursor: pointer;
}

.text {
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
}
