.userData {
  display: flex;
  gap: 12px;
  align-items: center;
}

.userDataTextContainer {
  margin-top: -4px;
}

.userBadge {
  width: 17.44px;
  height: 15.07px;
}

.userDataName {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.userDateContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 2px;
}

.userDataStatus {
  color: #7e88bb;
  font-size: 12px;
  font-weight: 500;
}
