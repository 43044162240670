.addPhotoBlock {
  gap: 38px;
}

.addPhotoGallery {
  width: 444px;
}

.galleryInputText {
  margin-top: 6px;
  line-height: 24px;
}

.addPhotoGalleryHeader {
  align-items: start;
  height: 24px;
  gap: 6px;
}

.addGallery {
  margin-top: 30px;
}

.addGalleryDivider {
  visibility: hidden;
  position: absolute;
}

.addGalleryTextColor {
  color: #8ef84f !important;
}

.addPhotoButton {
  border-radius: 14px;
  border: 1px solid #3b4267;
  padding: 41px 25px 46px 25px;
  align-items: center;
  gap: 5px;
  height: fit-content;
}

.addButtonIconContainer {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #2a3152;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButtonIcon {
  width: 24px;
  height: 24px;
}

.addPhotoTextBlock {
  margin-top: 5px;
  gap: 5px;
}

.addPhotoWhiteText {
  cursor: pointer;
}

.undrline {
  width: 100%;
  height: 1px;
  background: #ffffff;
}

.addPhotoFormatText {
  margin-top: 8px;
}
