.typeText {
  margin-bottom: 48px;
  margin-left: 16px;
}

.statusText {
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 16px;
}

.buttonsBox {
  gap: 8px;
}
