.contaner {
  border-radius: 30px;
  background: #1a1d3c;
  padding: 24px 29px 24px 24px;
}

.list {
  margin-top: 26px;
  gap: 19px;
  max-height: 372px;
  overflow-y: scroll;
  scrollbar-width: thin;
  /* для Firefox */
  scrollbar-color: #2d355d #7b4cff;
  /* для Firefox */
  padding-right: 25px;
}

/* Стили скроллбара для браузеров на базе WebKit (Chrome, Safari) */
.list::-webkit-scrollbar {
  width: 4px;
}

.list::-webkit-scrollbar-thumb {
  background: #7b4cff;
  border-radius: 30px;
}

.list::-webkit-scrollbar-track {
  background: #2d355d;
  border-radius: 30px;
}
