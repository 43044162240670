.container {
  margin-top: 90px;
}

.headerText {
  justify-content: space-around;
}

.pageText {
  color: #7e88bb;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  flex: 1;
  cursor: pointer;
  user-select: none;
}

.pageText.active {
  color: #ffffff;
}

.headerDividers {
  align-items: center;
  margin-top: 8px;
}

.divider {
  background: #333a70;
  flex: 1;
  margin-top: 16px;
  height: 1px;
}

.divider.active {
  height: 2px;
  background: #7b4cff;
}

.pageContainer {
  margin-top: 49px;
}
