.container {
  width: 249px;
  border-radius: 24px;
  background: #1a1d3b;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.photo {
  width: 248px;
  height: 252px;
  object-fit: cover;
  border-radius: 14px;
}

.carNameText {
  margin-top: 24px;
}

.carSubText {
  margin-top: 3px;
}

.priceContainer {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}
