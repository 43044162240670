.container {
  margin-top: 211px;
  position: relative;
  height: 725px;
}

.textContainer {
  position: relative;
  top: 269px;
  left: 37px;
  z-index: 1;
}

.headerText {
  margin-top: 21px;
  margin-left: 55px;
}

.itemsList {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 32px;
  width: 381px;
  gap: 30px;
}

.item {
  display: flex;
  gap: 16px;
}

.button {
  position: absolute;
  left: 26px;
  top: 638px;
  z-index: 1;
}
