.textContainer {
  width: 463px;
  position: absolute;
  right: 44px;
  top: 95px;
}

.headerNumber {
  text-align: end;
}

.headerText {
  margin-top: 21px;
  margin-left: 55px;
}

.itemsList {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 32px;
  width: 398px;
  gap: 30px;
}

.item {
  display: flex;
  gap: 16px;
}
