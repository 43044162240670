.container {
  gap: 24px;
}

.pagination {
  margin-top: 32px;
}

.divider {
  margin: 40px 0;
  background: #333a70;
  height: 1px;
}
