.buttonsBlock {
  gap: 16px;
  height: 50px;
}

.loaderBlock {
  height: 50px;
  align-items: center;
  justify-content: center;
}

.buttonsBlock > :last-child {
  margin: auto 0 auto auto;
}
