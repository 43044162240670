.container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #7b4cff;
  background: #1e2242;
  display: flex;
  cursor: pointer;
}

.triangleRight {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 6px solid #ffffff;
  border-bottom: 6px solid transparent;
  margin: auto;
}

.triangleLeft {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 6px solid #ffffff;
  border-bottom: 6px solid transparent;
  margin: auto;
}
