.container {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.arrow {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 0.6px;
  height: 0.6px;
}
