.container {
  position: absolute;
  right: 274px;
  top: -56px;
  width: 263px;
}

.header {
  gap: 12px;
  align-items: center;
}

.testButton {
  margin-top: 15px;
}

.testButtonText {
  font-size: 14px;
}

.checkboxContainer {
  gap: 13px;
  margin-top: 18px;
  margin-left: 9px;
}

.checkbox {
  justify-content: space-between;
}

.divider {
  margin-top: 17px;
  margin-left: 10px;
  margin-bottom: 12px;
}

.greenText {
  border-radius: 14px;
  background: rgba(142, 248, 79, 0.1);
  padding: 12px 12px;
  margin-top: 15px;
  text-align: center;
}
