.container {
  border-radius: 30px;
  background: #141631;
  padding: 24px;
  gap: 24px;
}

.header {
  justify-content: space-between;
  flex: 1;
}

.body {
  gap: 24px;
}

.star {
  margin: 3px auto auto 4px;
}

.arrowContainer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(142, 248, 79, 0.1);
}

.arrow {
  width: 20px;
  height: 20px;
}
