.header {
  gap: 32px;
}

.headerImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 384px;
  height: 384px;
  position: relative;
}

.iconDisable {
  opacity: 0.4;
}

.headerNameBlock {
  justify-content: space-between;
}

.headerText {
  width: 300px;
  word-wrap: break-word;
}

.cross {
  margin: 3px 1px auto auto;
  cursor: pointer;
}

.cardImage {
  width: 384px;
  height: 384px;
  border-radius: 14px;
  object-fit: contain;
}

.headerTextBlock {
  gap: 2px;
  flex-grow: 1;
}

.headerDivider {
  margin-top: 8px;
}

.buttonContainer {
  gap: 19px;
  margin-top: 36px !important;
}

.disable {
  pointer-events: none;
  color: var(--grey-scarlet-color);
}

.copyButton {
  margin-top: 8px;
}
