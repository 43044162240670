.header {
  width: 100%;
  margin-top: 24px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1120px;
  margin: auto;
}

.buttonsBlock {
  display: flex;
  gap: 31px;
  align-items: center;
}

.notAuthContainer {
  width: 1120px;
  display: flex;
  align-items: center;
  margin: auto;
}

.notAuthButtonsBlock {
  display: flex;
  gap: 31px;
  align-items: center;
  margin-left: 96px;
  margin-right: auto;
}
