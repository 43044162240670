.priceContainer {
  position: absolute;
  left: 286px;
  top: 249px;
  padding: 18px 20px;
  gap: 7px;
}

.priceText {
  border-radius: 30px;
  background: rgba(126, 136, 187, 0.1);
  padding: 4px 8px;
  width: fit-content;
}

.priceFilterContainer {
  justify-content: space-between;
  padding: 0px 11px 0px 7px;
}

.priceValue {
  gap: 8px;
}
