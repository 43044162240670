.container {
  cursor: pointer;
}

.input {
  background: var(--grey-dark-color);
  border: none;
  padding: 12px 19px;
  border-radius: 6px;
  font-size: 18px;
}

input:focus {
  outline: none;
}

.spread {
  width: 48px;
  height: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
