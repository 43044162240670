.button {
  border: none;
  cursor: pointer;
  background: transparent;
  height: 20px;
}

.icon {
  width: 20px;
  height: 20px;
}

.text {
  color: #8ef84f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.status {
  cursor: pointer;
  gap: 4px;
  border-radius: 30px;
  background: rgba(142, 248, 79, 0.1);
  padding: 2px 8px;
  align-items: center;
}
