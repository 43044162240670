.container {
  width: 417px;
  padding: 64px;
  border-radius: 30px;
  background-color: #181a3a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  z-index: 1;
}

.textContainer {
  display: flex;
  justify-content: space-around;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.logo {
  width: 95.632px;
  height: 104px;
}

.formContainer {
  width: 100%;
}

.formHeaderText {
  font-size: 20px;
  cursor: default;
}

.formHeaderText.inActive {
  color: #7e88bb;
  cursor: pointer;
}

.lineContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
}

.line {
  width: 100%;
  margin: auto;
}

.line.active {
  background-color: #7b4cff;
  height: 3px;
}

.line.inActive {
  background-color: #38325b;
  height: 1px;
}

.formContainer {
  margin-top: 24px;
}
