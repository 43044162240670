.buttonsContainer {
  margin-top: 16px;
}

.catalogButtonsBox {
  flex: 1;
  gap: 16px;
}

.catalogButtonsBox.vipDisable {
  margin-top: 16px;
}

.button {
  flex: 1;
}

.tradeBox {
  justify-content: space-between;
  flex: 1;
}

.traderInfoBlock {
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--purple-color);
}

.traderTextBlock {
  gap: 4px;
}

.infoButtonsBox {
  gap: 16px;
}

.call {
  background: #202d34;
}

.chat {
  background: #1e2c43;
}
