.button {
  background: var(--grey-dark-color);
  padding: 12px 12px 12px 16px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.button.back {
  padding: 12px 16px 12px 12px;
}

.button.active {
  background: transparent;
  cursor: default;
}
