.headerText {
  color: #7e88bb;
  font-style: normal;
  font-weight: 500;
  border-radius: 30px;
  background: rgba(126, 136, 187, 0.1);
  padding: 4px 8px;
  width: fit-content;
  margin-bottom: 20px;
}

.list {
  gap: 24px;
  flex-wrap: wrap;
}
