.textContainer {
  width: 229px;
  margin-top: 32px;
  padding: 0 36px;
  z-index: 2;
}

.headerText {
  line-height: 40px;
  width: 200px;
}

.subText {
  margin-top: 25px;
  line-height: 16px;
  width: 224px;
}

.button {
  margin-top: 24px;
  height: 64px;
}
