.div {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 14px;
  background: rgba(117, 242, 235, 0.1);
}
