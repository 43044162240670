.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  margin-top: 2px;
}

.loaderContainer {
  position: absolute;
  background-color: #262357;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
