.container {
  border-radius: 30px;
  background: #262357;
  padding: 16px;
  gap: 16px;
}

.avatar {
  width: 120px;
  height: 120px;
}

.body {
  flex: 1;
  justify-content: space-between;
}

.headerBlock {
  justify-content: space-between;
}

.textBlock {
  margin-top: 3px;
  gap: 4px;
}

.messageBlock {
  border-radius: 14px;
  padding: 8px 16px;
  background: rgba(123, 76, 255, 0.2);
  gap: 5px;
}
