.button {
  width: 100%;
  background-color: #60ae32;
  border-radius: 14px;
  padding: 16px 20px;
  border: 4px solid #324924;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.text {
}

.button.disable {
  background-color: #160a38;
  border-color: #7b4cff;
  box-shadow: 0 0 5px #7b4cff;
}
