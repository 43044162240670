.codeContainer {
  margin-top: 24px;
}

.input {
  margin: 0 8px;
  width: 9px;
  border-radius: 14px;
  border: 1px solid #32395b;
  background-color: transparent;
  width: 42px;
  height: 61px;
  text-align: center;
}

.input:focus {
  outline: none;
}

.inputWithValue {
  margin: 0 8px;
  width: 9px;
  border-radius: 14px;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid #60ae32;
  color: #ffffffb3;
  width: 42px;
  height: 61px;
  text-align: center;
}

.inputError {
  margin: 0 8px;
  width: 9px;
  border-radius: 14px;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid #f85a5a;
  color: #ffffffb3;
  width: 42px;
  height: 61px;
  text-align: center;
}
