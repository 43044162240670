.button {
  padding: 14px 16px 11px 16px;
  border-radius: 14px;
  background: var(--purple-very-dark-color);
  width: 205px;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.button.active {
  background: var(--purple-dark-color);
}

.frame {
  margin: auto 8px auto 0;
}

.text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  margin: auto 0 auto auto;
}

.dropContainer {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: calc(100% - 32px);
  padding: 12px 16px;
  border-radius: 14px;
  background: var(--grey-color);
  cursor: default;
  z-index: 2;
}

.itemsSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 152px;
  overflow-y: scroll;
  scrollbar-width: thin;
  /* для Firefox */
  scrollbar-color: #2d355d #7b4cff;
  /* для Firefox */
  padding-right: 25px;
}

/* Стили скроллбара для браузеров на базе WebKit (Chrome, Safari) */
.itemsSection::-webkit-scrollbar {
  width: 4px;
}

.itemsSection::-webkit-scrollbar-thumb {
  background: #7b4cff;
  border-radius: 30px;
}

.itemsSection::-webkit-scrollbar-track {
  background: #2d355d;
  border-radius: 30px;
}

.item {
  cursor: pointer;
}

.itemText:hover {
  color: #ffffff var(--white-color);
}

.itemText.active {
  color: #ffffff var(--white-color);
}
