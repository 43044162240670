.container {
  border-radius: 16px;
  background: #2a3151;
  padding: 28px;
  width: 200px;
  position: relative;
}

.header {
  display: flex;
  gap: 8px;
}

.avatar {
  border-radius: 50%;
}

.nameContainer {
  display: flex;
  gap: 8px;
}

.who {
  color: #7b4cff;
}

.date {
  color: #7e88bb;
  font-size: 12px;
  font-weight: 400;
  margin-top: 1px;
}

.message {
  margin-top: 10px;
  line-height: 24px;
}
