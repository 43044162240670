.modal {
  border-radius: 30px;
  background: #141631;
  padding: 52px 48px 48px 48px;
  width: 640px;
}

.header {
  justify-content: space-between;
  align-items: center;
}

.cross {
  cursor: pointer;
}

.list {
  gap: 10px;
}

.listItem {
  justify-content: space-between;
  gap: 20px;
}

.listDataName {
  width: 150px;
}

.listDataText {
  width: 70%;
  text-align: end;
}

.statusText {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.button {
  border-radius: 14px;
  background: #7b4cff;
  height: 56px;
}
