.filterBlock {
  position: absolute;
  left: 62px;
}

.textContainer {
  width: 463px;
}

.headerNumber {
  text-align: end;
}

.headerText {
  margin-top: 21px;
  margin-left: 55px;
}

.itemsList {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 32px;
  width: 398px;
  gap: 30px;
}

.item {
  display: flex;
  gap: 16px;
}

.input {
  gap: 6px;
  border-radius: 20px;
  border: 1px solid #333a70;
  align-items: center;
  padding: 14px 16px;
  margin-top: 2px;
}

.inputDivider {
  background: #ffffff;
  width: 2px;
  height: 24px;
}

.filtersHeader {
  margin-top: 17px;
  margin-left: 14px;
}

.checkContainer {
  padding: 12px 16px 9px;
  border-radius: 14px;
  background: #262357;
  justify-content: space-between;
  margin-top: 17px;
}

.checkHeaderText {
  border-radius: 30px;
  background: rgba(126, 136, 187, 0.2);
  padding: 4px 8px;
  width: fit-content;
}

.filtersContainer {
  margin-top: 13px;
  gap: 12px;
}

.additionallyText {
  border-radius: 30px;
  background: rgba(126, 136, 187, 0.1);
  padding: 4px 8px;
  width: fit-content;
  margin-top: 14px;
}

.tumblersContainer {
  margin-top: 18px;
  gap: 11px;
  padding: 0 8px;
  width: 173px;
}

.specialTumbler {
  justify-content: space-between;
}
