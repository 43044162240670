.header {
  width: 100%;
  margin-top: 66px;
  z-index: 3;
}

.container {
  display: flex;
  align-items: center;
  width: 1120px;
  margin: auto;
}

.buttonsBlock {
  display: flex;
  gap: 28px;
  align-items: center;
  margin-left: 29px;
}

.linksBlock {
  display: flex;
  gap: 25px;
  align-items: center;
  width: 267px;
  margin-left: 27px;
}

.notAuthContainer {
  width: 1120px;
  display: flex;
  align-items: center;
  margin: auto;
  height: 56px;
}

.notAuthButtonsBlock {
  display: flex;
  gap: 21px;
  align-items: center;
  margin: auto auto 10px 96px;
}
