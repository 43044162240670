.button {
  border-radius: 6px;
  padding: 12px 15px;
  min-width: 17px;
  display: flex;
  letter-spacing: 0;
  justify-content: center;
  cursor: pointer;
  color: var(--grey-scarlet-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.button.active {
  color: var(--white-color);
  background: var(--purple-color);
  cursor: default;
}
