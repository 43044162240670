.aboutBlock {
  flex: 1;
  margin-top: 2px;
}

.categoryText {
  margin-top: 6px;
}

.infoButton {
  background: var(--grey-color);
  width: 144px;
  height: 36px;
  margin-top: 10px;
  padding: 9px 12px 12px 10px;
  justify-content: space-around;
}
