.shopContainer {
  position: absolute;
  left: 285px;
  top: 72px;
  padding: 21px 19px 22px;
}

.shopHeader {
  gap: 8px;
  align-items: center;
}

.gorizontalDivider {
  width: 20px;
  background: #7e88bb;
}

.dropText {
  margin-left: 10px;
  letter-spacing: 2px;
}

.shopPageContainer {
  margin-top: 19px;
  gap: 16px;
  align-items: center;
}

.iconsContainer {
  gap: 8px;
}
