:root {
  --white-color: #ffffff;

  --grey-color: #2a3152;
  --grey-scarlet-color: #7e88bb;
  --grey-dark-color: #1a1d3c;

  --green-color: #8ef84f;
  --green-scarlet-color: #25333d;
  --green-dark-color: #293423;
  --green-dark-scarlet-color: #60ae32;

  --light-green-color: #cffb58;

  --purple-color: #7b4cff;
  --purple-dark-color: #262357;
  --purple-dark-scarlet-color: #241a51;
  --purple-very-dark-color: #141631;

  --azure-color: #75f2eb;
  --azure-scarlet-color: #324a61;
  --azure-dark-color: #23324c;

  --blue-color: #504cff;

  --yellow-color: #f8d55a;

  --drown-color: #271e25;
  --drown-scarlet-color: #3c302a;

  --red-color: #f85a5a;
}
