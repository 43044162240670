.document {
  display: flex;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
}

.documetnText {
  color: #7e88bb;
  font-size: 14px;
}

.documetnText.active {
  color: #ffffff;
}
