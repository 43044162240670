.header {
  gap: 40px;
}

.textBlock {
  gap: 16px;
}

.icon {
  width: 40px;
  height: 40px;
}

.divider {
  background: #333a70;
}
