.section {
  margin-top: 54px;
}

.body {
  border-radius: 32px;
  background: #101028;
  padding: 48px;
  gap: 40px;
}

.chooseTypeAndServerBlock {
  justify-content: space-between;
  gap: 32px;
}

.submitBlock {
  border-radius: 20px;
  background: #1e1c45;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
}

.tublerBlock {
  gap: 12px;
}

.buttonsBlock {
  gap: 32px;
}

.cancellButtonText {
  color: #f85a5a;
}

.submitButton {
  border-radius: 14px;
  background: #7b4cff;
}

.vipPhoneBlock {
  gap: 12px;
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
  margin-right: auto;
}

.lockIcon {
  width: 26px;
  height: 26px;
  margin-right: 9px;
}

.badgeBlock {
  border-radius: 30px;
  background: #504cff;
  gap: 8px;
  padding: 0 8px;
  align-items: center;
  cursor: pointer;
  height: 24px;
  margin-top: 2px;
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.infoPhoneButton {
  height: unset;
  padding: 0;
}
