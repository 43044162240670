.header {
  align-items: center;
  gap: 40px;
}

.divider {
  flex: 1;
}

.propertyesBlock {
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}

.property {
  justify-content: space-between;
  align-items: center;
}
