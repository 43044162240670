.headerTextFirst {
  width: 272px;
}

.headerText {
  width: fit-content;
  text-align: center;
}

.headerText:first-child {
  width: 272px;
  text-align: start;
  margin-left: 8px;
}

.headerText:nth-child(2) {
  margin-left: 76px;
}

.headerText:nth-child(3) {
  margin-left: 173px;
}

.headerText:last-child {
  text-align: end;
  margin-right: 16px;
  margin-left: auto;
}

.list {
  gap: 16px;
  margin-top: 25px;
}
