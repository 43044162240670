.container {
  border-radius: 30px;
  border: 1px solid #1a1d3c;
  background: #1a1d3c;
  padding: 24px;
  gap: 16px;
}

.baseButtonsBlock {
  gap: 16px;
}

.baseButton {
  flex: 1;
}
