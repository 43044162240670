.card {
  border-radius: 14px;
  background: #2a3152;
  padding: 0 12px;
  height: 60px;
  align-items: center;
  display: flex;
}

.dateBlock {
  width: 259px;
  gap: 8px;
}

.priceBlock {
  width: 285px;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.cardBlock {
  width: 278px;
  align-items: center;
  justify-content: center;
}

.status {
  margin: auto 0 auto auto;
}
