.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(10, 9, 32, 0.82) 0%, #0a0920 22.48%);
  align-items: center;
  display: flex;
}

.loader {
  border: 4px solid transparent;
  border-top: 4px solid #60ae32;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
