.container {
  border-radius: 14px;
  background: #2a3152;
  min-width: 146px;
  max-width: fit-content;
  padding: 12px 16px;
}

.headerText {
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  border-radius: 30px;
  background: rgba(126, 136, 187, 0.1);
  padding: 4px 8px;
  width: fit-content;
  margin-bottom: 12px;
}

.list {
  max-height: 154px;
  overflow-y: scroll;
  scrollbar-width: thin;
  /* для Firefox */
  scrollbar-color: #2d355d #7b4cff;
  /* для Firefox */
  padding-right: 25px;
}

/* Стили скроллбара для браузеров на базе WebKit (Chrome, Safari) */
.list::-webkit-scrollbar {
  width: 4px;
}

.list::-webkit-scrollbar-thumb {
  background: #7b4cff;
  border-radius: 30px;
}

.list::-webkit-scrollbar-track {
  background: #2d355d;
  border-radius: 30px;
}

.listStyles {
  gap: 12px;
  padding: 0;
  display: flex !important;
  flex-direction: column !important;
}
