.section {
  margin-top: 64px;
  margin-bottom: 104px;
}

.headerDivider {
  margin-top: 40px;
  margin-bottom: 40px;
  height: 1px;
}

.chatsBlock {
  gap: 16px;
}
