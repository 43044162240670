.container {
  padding: 18px 18px 32px;
  background-color: #2a3151;
  border-radius: 20px;
  width: 253px;
  position: absolute;
  left: 284px;
  top: 99px;
}

.headerText {
  margin-left: 19px;
}

.button {
  border-radius: 14px;
  background: #262357;
  display: flex;
  padding: 12px 23px 12px 17px;
  width: 211px;
  align-items: center;
  margin-top: 18px;
}

.buttonIcon {
  margin-right: 16px;
}

.buttonArrow {
  margin: auto 0 auto auto;
}

.filterContainer {
  display: flex;
  gap: 28px;
  margin-top: 33px;
  padding-left: 30px;
}

.filterButton {
  display: flex;
  justify-content: space-between;
  width: 169px;
}

.filterCategoryes {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 12px 16px 20px;
}

.inactiveButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 23px;
}
