.section {
  margin-top: 149px;
  height: 532px;
  position: relative;
}

.button {
  position: absolute;
  width: 233px;
  left: 28px;
  bottom: 31px;
  z-index: 1;
}
