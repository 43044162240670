.wrapper {
  margin-bottom: 106px;
}

.section {
  padding: 52px 42px 46px 42px;
  border-radius: 32px;
  background: #101028;
}

.body {
  margin-top: 40px;
  gap: 30px;
}

.transactionModal {
  top: 20%;
}
