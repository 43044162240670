.container {
  border-radius: 20px;
  background: #1a1d3c;
  padding: 24px;
  gap: 44px;
  width: 100%;
}

.header {
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 8px;
  margin-left: 13px;
}

.divider {
  background: #333a70;
  width: 155px;
  margin: auto 8px auto auto;
}

.body {
  width: 100%;
  z-index: 3;
}

.dropdown {
  width: 416px;
  z-index: 1;
}
