.wrapper {
  width: 100%;
  margin: 35px auto 0 !important;
}

.headerContainer {
  width: 1120px;
  margin: 0 auto;
}

.searchContainer {
  margin: 40px auto 0 auto;
  width: 1063px;
}

.searchInput {
  margin-top: 24px;
  width: 1038px !important;
}

.slider {
  margin: 44px auto 0;
  width: 1063px !important;
}

.shopBarBody {
  justify-content: space-between;
  width: 1119px;
}

.itemsBlock {
  gap: 40px;
  width: 831px;
}
