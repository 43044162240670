.bodyHeader {
  justify-content: space-between;
  gap: 62px;
}

.itemPhoto {
  width: 372px;
  height: 372px;
  border-radius: 14px;
}

.bodyData {
  margin-top: 3px;
  flex: 1;
  gap: 5px;
}

.bodyDataHeader {
  gap: 24px;
}

.bodyDataHeaderText {
  max-width: 420px;
}

.bodyDataHeaderStatus {
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 30px;
  background: #25333d;
  margin: 4px 2px auto auto;
}

.headerDivider {
  background: #333a70;
  margin: 21px 8px;
  width: 524px;
  height: 1px;
}

.bodyDataInputs {
  justify-content: space-between;
  position: relative;
}

.bodyDataInputsPriceBlock {
  width: 272px;
}

.bodyDataPrice {
  margin-bottom: 24px;
}

.bodyDataInputsCountBlock {
  gap: 24px;
  width: 244px;
}

.headerGalerry {
  margin-top: 21px;
  width: 525px;
}

.galleryDivider {
  height: 1px;
}
