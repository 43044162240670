.button {
  border-radius: 12px;
  background: var(--purple-dark-color);
  width: fit-content;
  gap: 10px;
  padding: 8px 8px 8px 16px;
  cursor: pointer;
  display: flex;
}

.button.active {
  background: var(--green-dark-color);
}

.disable {
  pointer-events: none;
  color: var(--grey-scarlet-color);
  background: var(--grey-color);
}

.imageDisable {
  filter: sepia(100%) hue-rotate(184deg) brightness(80%);
}
