.slider {
  position: relative;
}

.sliderContainer {
  width: calc(100% - 18px);
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  display: flex;
}

.itemContainer {
  display: flex;
  padding: 0 32px;
  border-radius: 10px;
  background: var(--grey-dark-color);
  align-items: center;
  height: 32px;
  width: fit-content;
  user-select: none;
  cursor: pointer;
  margin-right: 17px;
}

.itemContainer:hover {
  .itemText {
    color: var(--white-color);
  }
}

.itemText {
  color: var(--grey-scarlet-color);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  user-select: none;
}

.leftArrow {
  position: absolute;
  top: -2px;
  left: 0;
  z-index: 1;
  width: 36px;
  height: 36px;
}

.rightArrow {
  position: absolute;
  top: -2px;
  right: 0;
  z-index: 2;
  width: 36px;
  height: 36px;
}

.rightShadow {
  top: -3px;
  right: 0;
  z-index: 2;
  width: 36px;
  height: 36px;
}

.leftShadow {
  top: -3px;
  left: 0;
  z-index: 1;
  width: 36px;
  height: 36px;
}
