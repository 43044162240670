.container {
  border-radius: 14px;
  padding: 12px;
  gap: 4px;
}

.container.buy {
  background: rgba(117, 242, 235, 0.1);
}

.container.cell {
  background: rgba(207, 251, 83, 0.1);
}

.price {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.price.buy {
  color: #75f2eb;
}

.price.cell {
  color: #cffb53;
}

.value {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.value.buy {
  color: #75f2eb;
}

.value.cell {
  color: #cffb53;
}
