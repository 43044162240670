.buttonsContainer {
  gap: 20px;
  width: fit-content;
}

.headerText {
  margin-left: 16px;
}

.allButton {
  gap: 18px;
  cursor: pointer;
  border-radius: 14px;
  min-width: 224px;
  height: 48px;
  padding: 0 16px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-top: 28px;
}

.allButton.active {
  background: var(--purple-very-dark-color);
}
