.container {
  border-radius: 14px;
  background: transparent;
  gap: 8px;
  border: 1px solid #2d355d;
  cursor: pointer;
  padding: 0 16px;
  height: 48px;
  max-width: fit-content;
  min-width: 86px;
}

.container.active {
  background: #7b4cff;
  border: 1px solid #7b4cff;
}

.checkIcon {
  width: 16px;
  height: 16px;
  margin: auto 0;
}

.text {
  margin: auto 0;
}

.percent {
  color: #cffb53;
  font-size: 16px;
  font-style: normal;
  margin: 5px auto auto auto;
}
