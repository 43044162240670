.card {
  border-radius: 14px;
  background: #2a3152;
  padding: 12px;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.name {
  margin-right: 20px;
  width: 170px;
}

.price {
  margin-right: 20px;
  width: 120px;
}

.date {
  margin-right: 20px;
  margin-left: auto;
  width: 150px;
}
