.buttonsContainer {
  gap: 19px;
  margin-top: 16px;
  align-items: center;
}

.buttonsContainer.vipDisable {
  margin-top: 32px;
}

.saleButton {
  height: 56px !important;
  width: 100%;
}

.buyButton {
  height: 48px !important;
  width: calc(100% - 8px);
}

.buyButton.active {
  height: 56px !important;
  width: 100%;
}
