.container {
  border-radius: 30px;
  gap: 2px;
  width: 79px;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.container.buy {
  background: rgba(207, 251, 83, 0.3);
}

.container.cell {
  background: rgba(117, 242, 235, 0.3);
}

.text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.text.buy {
  color: #cffb53;
}

.text.cell {
  color: #75f2eb;
}

.icon {
  width: 12px;
  height: 12px;
}
