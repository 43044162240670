.container {
  padding: 20px;
  width: 132px;
  position: absolute;
  top: 98px;
  left: 373px;
}

.loadingContainer {
  margin-top: 14px;
  gap: 9px;
}

.itemName {
  margin-top: 16px;
  line-height: 24px;
}

.itemPrice {
  margin-top: 8px;
  gap: 9px;
}

.photo {
  width: 131px;
  height: 108px;
  border-radius: 14px;
}
