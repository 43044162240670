.section {
  width: 475px;
  position: absolute;
  top: -68px;
  left: 63px;
  border-radius: 24px;
  padding: 25px 20px 22px;
}

.header {
  gap: 43px;
}

.headerBookmarks {
  gap: 8px;
}

.body {
  margin-top: 23px;
}

.chartContainer {
  gap: 23px;
}

.chartPriceColumn {
  gap: 30px;
  margin-left: 1px;
}

.chartTable {
  width: 399px;
  position: relative;
}

.dotFirst {
  position: absolute;
  top: 87px;
  left: 77px;
}

.dotSecond {
  position: absolute;
  top: -5px;
  right: 70px;
}

.priceContainer {
  width: 60px;
  border-radius: 12px;
  background: rgba(42, 49, 82, 0.8);
  backdrop-filter: blur(2px);
  gap: 4px;
  padding: 16px 16px 20px;
  position: absolute;
}

.priceContainer.first {
  top: 90px;
  left: 99px;
}

.priceContainer.second {
  top: -5px;
  right: 95px;
}

.chartTimeRow {
  gap: 48px;
  margin-left: 62px;
  margin-top: 5px;
}

.footer {
  gap: 8px;
  margin-top: 26px;
  margin-left: 1px;
}

.footerHeader {
  border-radius: 30px;
  background: rgba(123, 76, 255, 0.2);
  padding: 4px 8px;
  margin-right: 8px;
}
