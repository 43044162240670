.Text10Green {
  color: var(--green-color);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.Text14Green {
  color: var(--green-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.Text12Green {
  color: var(--green-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.Text16Green {
  color: var(--green-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.Text18Green {
  color: var(--green-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.Text20Green {
  color: var(--green-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.Text24Green {
  color: var(--green-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.Text32Green {
  color: var(--green-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}

.Text48Green {
  color: var(--green-color);
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
}

.Text64Green {
  color: var(--green-color);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
}

.Text12White {
  color: var(--white-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.Text12WhiteRoboto {
  color: var(--white-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: 'Roboto Flex';
}

.Text14White {
  color: var(--white-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.Text14WhiteRoboto {
  color: var(--white-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-family: 'Roboto Flex';
}

.Text16White {
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.Text16WhiteRoboto {
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  font-family: 'Roboto Flex';
}

.Text18White {
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.Text20White {
  color: var(--white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.Text24White {
  color: var(--white-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.Text31White {
  color: var(--white-color);
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
}

.Text32White {
  color: var(--white-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}

.Text40White {
  color: var(--white-color);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}

.Text48White {
  color: var(--white-color);
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
}

.Text64White {
  color: var(--white-color);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
}

.Text12Grey {
  color: var(--grey-scarlet-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.Text12GreyRoboto {
  color: var(--grey-scarlet-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: 'Roboto Flex';
}

.Text14Grey {
  color: var(--grey-scarlet-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.Text16Grey {
  color: var(--grey-scarlet-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.Text18Grey {
  color: var(--grey-scarlet-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.Text24Grey {
  color: var(--grey-scarlet-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.Text48Grey {
  color: var(--grey-scarlet-color);
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
}

.Text14Purple {
  color: var(--purple-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.Text16Purple {
  color: var(--purple-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.Text48Purple {
  color: var(--purple-color);
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
}

.Text14Orange {
  color: #ffb342;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.Text16Yellow {
  color: var(--yellow-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.Text12Red {
  color: var(--red-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.Text14Red {
  color: var(--red-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
