.header {
  border-radius: 30px;
  position: relative;
  width: 100%;
  justify-content: space-between;
  background: #141631;
}

.filterSection {
  justify-content: space-between;
  border-radius: 30px;
  padding: 35px 47px 33px;
  background: var(--grey-color);
  gap: 24px;
  width: 367px;
}

.filterContainer {
  gap: 24px;
  align-items: center;
  width: fit-content;
}

.pageName {
  border-radius: 0 30px 30px 0;
  height: 184px;
}
