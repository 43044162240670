.container {
  gap: 16px;
  align-items: center;
}

.textBlock {
  text-align: end;
  gap: 1px;
  margin-top: 6px;
}
