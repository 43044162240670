.container {
  border-radius: 30px;
  background-color: #141631;
  padding: 48px;
}

.errorText {
  font-size: 32px;
  margin-top: 24px;
}

.text {
  color: #7e88bb;
  width: 765px;
  margin-top: 12px;
}

.buttonsContainer {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}

.button {
  padding: 0 24px;
  background-color: #2a3152;
  border-radius: 12px;
  height: 56px;
}
