.modal {
  border-radius: 30px;
  background: #141631;
  padding: 48px;
  gap: 24px;
  width: 448px;
  height: 530px;
  position: relative;
}

.modal.small {
  height: 285px;
}

.header {
  height: 45px;
}

.headerText {
  margin: 0 auto auto 0;
}

.headerCloseIcon {
  margin: 0 0 auto auto;
  cursor: pointer;
}

.inputHeader {
  gap: 8px;
}

.tick {
  margin: auto 0 auto auto;
}

.button {
  border-radius: 14px;
  background: #7b4cff;
  padding: 16px 24px;
  height: 56px;
  width: fit-content;
  margin: auto 0 0 auto;
}

.button.disable {
  cursor: no-drop;
}

.disableBlock {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  opacity: 0.4;
  cursor: progress;
}
