.footer {
  background: #141631;
  width: 100%;
  padding: 32px 0;
  z-index: 1;
}

.container {
  width: 1120px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 27px;
}

.copyrightBlock {
  display: flex;
  align-items: center;
  gap: 24px;
}

.social {
  display: flex;
  gap: 16px;
}

.copyrightText {
  font-size: 14px;
  color: #7e88bb;
}

.supportText {
  font-size: 14px;
}
