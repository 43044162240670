.container {
  gap: 40px;
  z-index: 2;
}

.inputBlock {
  gap: 16px;
  position: relative;
  z-index: 2;
}

.dropContainer {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: calc(100% - 32px);
  padding: 12px 16px;
  border-radius: 14px;
  background: var(--grey-color);
  cursor: default;
  z-index: 2;
}

.itemsSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 167px;
  overflow-y: scroll;
  scrollbar-width: thin;
  /* для Firefox */
  scrollbar-color: #2d355d #7b4cff;
  /* для Firefox */
  padding-right: 25px;
}

/* Стили скроллбара для браузеров на базе WebKit (Chrome, Safari) */
.itemsSection::-webkit-scrollbar {
  width: 4px;
}

.itemsSection::-webkit-scrollbar-thumb {
  background: #7b4cff;
  border-radius: 30px;
}

.itemsSection::-webkit-scrollbar-track {
  background: #2d355d;
  border-radius: 30px;
}

.item {
  cursor: pointer;
}

.itemText:hover {
  color: #ffffff var(--white-color);
}

.itemText.active {
  color: #ffffff var(--white-color);
}

.dropdownWall {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
