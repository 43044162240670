.container {
  margin-top: 24px;
  margin-bottom: 104px;
}

.header {
  justify-content: space-between;
}

.headerTextBlock {
  gap: 16px;
  align-items: center;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.pagination {
  margin-top: 54px;
}
