.icon {
  width: 40px;
  width: 40px;
  margin-right: 24px;
}

.text {
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
}

.crossIcon {
  margin: auto 0 auto auto;
  cursor: pointer;
}
