.container {
  align-items: center;
  margin: 0 0 0 auto;
}

.icon {
  width: 16px;
  height: 16px;
}

.button {
  background: transparent;
  height: 24px;
  padding: 0;
  gap: 4px;
}

.divider {
  height: 22px;
  margin: auto 16px;
}

.loaderBlock {
  border-radius: 30px;
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 300ms;
  background-color: black;
}

.loaderBlock.active {
  opacity: 0.5;
  pointer-events: all;
}
