.itemData {
  gap: 16px;
}

.statusBlock {
  gap: 8px;
}

.server {
  width: 79px;
  height: 20px;
  border-radius: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: rgba(248, 97, 97, 0.3);
}

.textBlock {
  margin-top: 3px;
  gap: 4px;
}

.itemName {
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  max-width: 395px;
  text-overflow: ellipsis;
}

.itemSub {
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  max-width: 395px;
  text-overflow: ellipsis;
}

.itemPhoto {
  border-radius: 14px;
  width: 48px;
  height: 48px;
}
