.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
}

.paddingContainer {
  height: 100px;
  width: 100%;
}

.childrenContainer {
  height: fit-content;
}
