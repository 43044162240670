.container {
  border-radius: 20px;
  background: #271e25;
  gap: 8px;
  padding: 24px;
  flex: 1;
  align-items: center;
  margin-bottom: 40px;
}

.icon {
  width: 24px;
  height: 24px;
}

.container2 {
    border-radius: 20px;
    background: #271e25;
    gap: 8px;
    padding: 24px;
    flex: 1;
    align-items: center;
    margin: 0 auto 20px;
    max-width: 1075px;
}
