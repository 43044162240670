.button {
  height: 48px;
  border-radius: 14px;
  background: transparent;
  cursor: pointer;
  border: 1px solid #2d355d;
  user-select: none;
  align-items: center;
  padding: 0 16px;
  width: 100%;
}

.button.active {
  background: var(--purple-dark-scarlet-color);
  border: 1px solid var(--purple-dark-scarlet-color);
}

.button.disable {
  border-radius: 11px;
  background: #1a1d3c;
}

.checkbox {
  width: 16px;
  height: 16px;
}

.text {
  margin: auto auto auto 16px;
}

.countText {
  margin: auto 0 auto 8px;
}

.vipDisable {
  border-radius: 14px;
  border: 1px solid var(--blue-color);
  width: 256px;
  height: 47px;
  padding: 4px 2.5px;
  align-items: center;
  position: relative;
  margin-top: 12px;
}

.vipText {
  position: absolute;
  gap: 8px;
  border-radius: 30px;
  background: #504cff;
  height: 24px;
  align-items: center;
  padding: 0 8px;
  top: -12px;
  right: 17px;
  cursor: pointer;
}

.vipBadge {
  width: 16px;
  height: 16px;
}

.textDisableColor {
  color: var(--grey-scarlet-color) !important;
}

.countDisableColor {
  color: var(--blue-color) !important;
}
