.section {
  margin: 86px auto 0 auto;
  background: #0e0e26;
  padding: 95px 116px 40px;
}

.content {
  max-width: 100%;
  min-width: 1119px;
  margin: 0 auto;
}

.productSection {
  margin-top: 24px;
  gap: 31px;
}
