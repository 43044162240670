.container {
  border-radius: 14px;
  border: 1px solid #7b4cff;
  padding: 12px 16px;
  gap: 8px;
}

.input {
  background: transparent;
  border: none;
  width: 100%;
}
