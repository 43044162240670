.card {
  padding: 27px 24px 18px 24px;
  border-radius: 14px;
  background: #2a3152;
  gap: 9px;
  border: 1px solid #2a3152;
  flex: 1;
  position: relative;
}

.card.disable {
  border: 1px solid #504cff;
  background: #1e1c45;
  cursor: pointer;
}

.cardData {
  justify-content: space-between;
}

.cardDataIcon {
  width: 32px;
  height: 32px;
  margin-top: 6px;
}

.cardDataGreyText {
  margin-top: 15px;
  margin-left: 4px;
}

.alertContainer {
  top: -12px;
  right: 8px;
}

.alertIcon {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}

.slash {
  margin-left: 4px;
}
