.section {
  margin-top: 113px;
  height: 475px;
  position: relative;
}

.button {
  position: absolute;
  width: 233px;
  right: 28px;
  bottom: 34px;
  z-index: 1;
}
