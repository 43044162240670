.container {
  padding: 0 8px;
  height: 20px;
  align-items: center;
  gap: 8px;
  display: flex;
  border-radius: 30px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
