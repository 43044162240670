.container {
  position: relative;
  border: none;
  padding: 12px;
  display: flex;
  align-items: center;
  background-color: #504cff40;
  width: 242px;
  border-radius: 14px;
  cursor: pointer;
  overflow: hidden;
}

.text {
  font-size: 12px;
  padding: 8px 12px;
  background-color: #504cff;
  border-radius: 8px;
  width: 113px;
}

.icon {
  position: absolute;
  left: 116px;
  top: -59px;
}
