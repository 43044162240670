.container {
  border-radius: 20px;
  border: 1px solid var(--grey-color);
  display: flex;
  justify-content: space-between;
  height: 62px;
  align-items: center;
  padding: 0 8px 0 16px;
}

.container.active {
  background: var(--purple-very-dark-color);
  border: 1px solid var(--purple-color);
}

.container.error {
  border: 1px solid var(--red-color);
}

.input {
  margin: 12px 16px 12px 13px;
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}

.input::placeholder {
  color: var(--grey-scarlet-color);
  font-size: 16px;
  font-weight: 500;
}

.button {
  background: var(--grey-dark-color) !important;
  padding: 16px 24px !important;
  border-radius: 14px !important;
  height: 48px !important;
}

.buttonActive {
  background: rgba(123, 76, 255, 0.2) !important;
  padding: 16px 24px !important;
  border-radius: 14px !important;
  height: 48px !important;
}

.crossIcon {
  cursor: pointer;
  margin: auto 16px;
}
