.container {
  display: flex;
  height: 16px;
  padding: 12px;
  gap: 8px;
  border-radius: 14px;
  background: #7b4cff;
  align-items: center;
  cursor: pointer;
}

.container.active {
  background: rgba(142, 248, 79, 0.1);
}

.icon {
  width: 16px;
  height: 16px;
}

.icon.active {
  width: 25px;
  height: 25px;
}
