.container {
  display: flex;
}

.textContainer {
  flex: 1;
  justify-content: end;
}

.commonText {
  font-size: '14px';
  text-align: end;
}

.underlinedText {
  position: relative;
  cursor: pointer;
  text-align: end;
}

.text {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #7b4cff;
}

.text:before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #7b4cff;
}
