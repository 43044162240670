.container {
  border-radius: 30px;
  border: 1px solid #1a1d3c;
  background: #1a1d3c;
  padding: 24px;
  gap: 16px;
}

.list {
  gap: 24px;
  flex-wrap: wrap;
  align-items: center;
}

.footer {
  gap: 4px;
  align-items: center;
  flex: 1;
}
