.container {
  width: 216px;
  gap: 16px;
}

.contactContainer {
  gap: 10px;
}

.contactInfo {
  gap: 12px;
}

.dividerContainer {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
