.button {
  cursor: pointer;
  user-select: none;
}

.text {
  margin: auto 16px auto 0;
}

.text.active {
  color: var(--purple-color) !important;
}

.arrow {
  margin: auto 16px auto auto;
}

.container {
  gap: 11px;
}
