.visible {
  visibility: visible;
}

.successType {
  padding: 24px;
  background: #324924;
  backdrop-filter: blur(38.5px);
  width: 246px;
  border-radius: 30px 0px 30px 30px;
}

.errorType {
  padding: 24px;
  background: #532d2d;
  backdrop-filter: blur(38.5px);
  width: 246px;
  border-radius: 30px 0px 30px 30px;
}

.warningType {
  padding: 24px;
  background: #352954;
  backdrop-filter: blur(38.5px);
  width: 246px;
  border-radius: 30px 0px 30px 30px;
}

.errorInfoContainer {
  display: flex;
  justify-content: space-between;
}

.icon {
  margin-top: 0;
  margin-bottom: auto;
}

.loader {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: transparent;
  overflow: hidden;
  margin-top: 20px;
}

.loader::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #40602c;
}

.loader::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #80de49;
  animation: loaderAnimation 6s linear;
}

@keyframes loaderAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.errorLoader {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: transparent;
  overflow: hidden;
  margin-top: 20px;
}

.errorLoader::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #743636;
}

.errorLoader::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #f85a5a;
  animation: errorLoaderAnimation 6s linear;
}

@keyframes errorLoaderAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.warningLoader {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: transparent;
  overflow: hidden;
  margin-top: 20px;
}

.warningLoader::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #372b5b;
}

.warningLoader::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #7b4cff;
  animation: warningLoaderAnimation 6s linear;
}

@keyframes warningLoaderAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
