.container {
  content: '';
  position: absolute;
  top: 0;
  width: 136px;
  height: 100%;
}

.container.right {
  background-image: linear-gradient(to left, #0a0920 0%, rgba(10, 9, 32, 0) 100%);
}

.container.left {
  background-image: linear-gradient(to right, #0a0920 0%, rgba(10, 9, 32, 0) 100%);
}
