.image {
  display: flex;
  border-radius: 14px;
  object-fit: contain;
}

.imageBlock {
  align-items: center;
  justify-content: center;
}

.iconDisable {
  opacity: 0.4;
}
