.header {
  gap: 8px;
  align-items: center;
}

.divider {
  flex: 1;
}

.imageName {
  margin-top: 5px;
  margin-bottom: 19px;
}

.imageName.hidden {
  visibility: hidden;
}
