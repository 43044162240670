.section {
  position: absolute;
  left: 63px;
  top: -35px;
  gap: 24px;
}

.contactCall {
  width: 192px;
  align-items: center;
  border-radius: 14px;
}

.avatar {
  width: 50px;
  height: 49px;
}

.name {
  margin-top: 4px;
}

.time {
  margin-top: 10px;
}

.icon {
  width: 48px;
  height: 48px;
  margin-top: 10px;
}

.contactContainer {
  width: 190px;
  background: #1a1d3c;
  box-shadow: 0px 48px 32px 0px rgba(14, 14, 38, 0.24);
  padding: 21px 19px 21px 24px;
}
