.dropdownsContainer {
  margin-left: 43px;
  gap: 16px;
}

.divider {
  height: 22px;
  margin: auto;
}

.filterButton {
  background: var(--purple-very-dark-color);
  height: 48px;
  margin: auto 0;
}

.filterButtonIcon {
  width: 32px;
  height: 32px;
}

.container {
  padding: 0 0 0 17px;
}

.typeDropdown {
  padding: 14px 17px 12px;
  width: 135px !important;
}

.sizeDropdown {
  padding: 14px 17px 12px;
  width: 144px !important;
}
