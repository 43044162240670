.container {
  position: relative;
  height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-decoration: none;
  padding: 4px 12px;
  border-radius: 6px;
}

.icon {
  flex-shrink: 0;
}

.text {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
}

.containerActive {
  background: #36326f;
  cursor: default;
}
