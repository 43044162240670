.container {
  width: 249px;
}

.imageCar {
  border-radius: 14px;
  width: 249px;
  height: 252px;
  object-fit: cover;
}

.carNameText {
  margin-top: 24px;
}

.carSubText {
  margin-top: 3px;
}

.priceContainer {
  gap: 8px;
  margin-top: 12px;
}

.buyInfoButton {
  margin-top: 15px;
  padding: 0 60px 0 21px !important;
}

.sellInfoButton {
  margin-top: 16px;
  padding: 0 60px 0 21px !important;
}
