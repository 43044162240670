.container {
  position: relative;
  margin-top: 120px;
  height: 600px;
}

.back {
  top: 57px;
  z-index: 1;
}

.filterBlock {
  position: absolute;
}

.button {
  position: absolute;
  right: 30px;
  bottom: 49px;
  width: 232px;
  z-index: 1;
}
