.container {
  margin-top: 16px;
  padding: 0 16px;
}

.headerDate {
  margin: 0 auto;
}

.chat {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
}

.scroll {
  overflow-y: scroll;
  scrollbar-width: thin;
  /* для Firefox */
  scrollbar-color: #2d355d #7b4cff;
  /* для Firefox */
  padding-right: 25px;
}

.scroll::-webkit-scrollbar {
  width: 4px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #7b4cff;
  border-radius: 30px;
}

.scroll::-webkit-scrollbar-track {
  background: #2d355d;
  border-radius: 30px;
}
