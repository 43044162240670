.container {
  gap: 20px;
  margin-top: 16px;
}

.property {
  align-items: center;
  flex: 1;
  justify-content: space-between;
}
