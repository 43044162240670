.container {
  border-radius: 30px;
  background: #141631;
  gap: 40px;
  padding: 32px;
  margin-top: 40px;
}

.cardsBlock {
  gap: 24px;
}
