.header {
  border-radius: 30px;
  background: #241a51;
  margin-top: 24px;
  align-items: center;
  position: relative;
  height: 80px;
  padding: 0 24px 0 0;
}

.header.disable {
  background: rgba(126, 136, 187, 0.3);
}

.icon {
  border-radius: 30px 0 0 30px;
  position: absolute;
}

.icon.disable {
  border-radius: unset;
  position: absolute;
  left: 16px;
}

.text {
  margin-left: 88px;
}

.subText {
  margin: auto 0 auto auto;
}

.subText.disable {
  color: #7e88bb !important;
}
