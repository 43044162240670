.section {
  margin-top: 120px;
  height: 600px;
  position: relative;
}

.button {
  position: absolute;
  bottom: 160px;
  left: 30px;
  z-index: 1;
}
