.container {
  padding: 22px 20px 19px;
  width: 280px;
  position: absolute;
  top: 61px;
  right: 64px;
}

.divider {
  background: #333a70;
  margin: 16px 9px 13px;
}

.usersList {
  gap: 20px;
}

.userContainer {
  justify-content: space-between;
}

.userAvatar {
  width: 48px;
  height: 48px;
  margin-top: 3px;
}

.userStatusContainer {
  gap: 10px;
  margin: auto auto auto 8px;
}

.userButtonsContainer {
  align-items: center;
  gap: 8px;
}

.icon {
  border-radius: 14px;
  background: rgba(142, 248, 79, 0.1);
  width: 20px;
  height: 20px;
  padding: 10px;
}
