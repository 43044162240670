.container {
  width: fit-content;
}

.button {
  display: flex;
  width: 256px;
  height: 48px;
  padding: 0 16px;
  border: 0;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-radius: 14px;
}

.buttonActive {
  background: #1a1d3c;
  backdrop-filter: blur(38.5px);
}

.icon {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.icon.rotated {
  animation: rotateAnimation 0.3s ease forwards;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.items {
  margin: 32px 16px 12px 28px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-left: 1px solid #7e88bb;
  padding-left: 28px;
}

.item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.activeText {
  color: #7b4cff;
}
