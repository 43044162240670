.wrapper {
  margin: 40px auto auto;
  width: 360px;
}

.catalogStyles {
  margin-top: 65px !important;
}

.catalogImage {
  width: 328px;
  height: 405px;
}

.catalogNumber {
  top: 367px;
  right: 44px;
}

.adsStyles {
  margin-top: 61px !important;
}

.adsNumber {
  top: 367px;
  right: 44px;
}

.filtersStyles {
  margin-top: 62px !important;
}

.filtersNumber {
  top: 282px;
  right: 44px;
}

.filtersItem {
  width: 271px !important;
}

.filtersImage {
  height: 322px !important;
}

.notificationsStyles {
  margin-top: 62px !important;
}

.notificationsNumber {
  top: 259px;
  right: 44px;
}

.notificationsItem {
  width: 271px !important;
}

.notificationsImage {
  height: 298px !important;
}

.chartStyles {
  margin-top: 62px !important;
}

.chartNumber {
  top: 276px;
  right: 44px;
}

.chartHeader {
  margin: 18px auto 0 44px !important;
}

.chartItem {
  width: 271px;
}

.chartImage {
  height: 305px !important;
}

.activityStyles {
  margin-top: 62px !important;
}

.activityNumber {
  top: 276px;
  right: 44px;
}

.activityHeader {
  margin: 18px auto 0 44px !important;
}

.activityImage {
  height: 305px !important;
}

.phoneStyles {
  margin-top: 62px !important;
}

.phoneNumber {
  top: 273px;
  right: 44px;
}

.phoneHeader {
  margin: 0 auto 0 44px !important;
  line-height: 48px;
}

.phoneImages {
  height: 315px !important;
}

.headerList {
  margin: 19px auto 0 45px !important;
}

.phoneItem {
  width: 266px !important;
}
