.itemTypeContainer {
  position: absolute;
  left: 286px;
  top: 382px;
  gap: 18px;
  height: 32px;
  align-items: center;
}

.itemType {
  border-radius: 8px;
  background: #2a3151;
  padding: 7px 31px;
  width: fit-content;
  align-items: center;
}

.itemScroll {
  width: 36px;
  height: 36px;
}

.itemType:last-child {
  margin-right: 5px;
}
