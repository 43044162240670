.container {
  position: absolute;
  top: 92px;
  left: 347px;
  width: 230px;
  gap: 20px;
}

.callContainer {
  padding: 22px 20px 22px 20px;
}

.phoneContainer {
  align-items: center;
  margin-top: 15px;
}

.icon {
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 14px;
  background: rgba(142, 248, 79, 0.1);
}

.phone {
  margin-left: 11px;
}
