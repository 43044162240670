.container {
  border-radius: 20px;
  background: #1a1d3c;
  padding: 20px 20px;
  gap: 30px;
  width: 792px;
}

.filterTypeBlock {
  gap: 18px;
}

.divider {
  height: 1px;
  background: #333a70;
  width: 100%;
}

.submitBlock {
  gap: 32px;
  align-items: center;
  justify-content: end;
}

.cancel {
  background: transparent;
  height: fit-content;
  width: fit-content;
  padding: 0 !important;
}

.submit {
  height: 56px;
  width: 123px;
  background: #7b4cff;
}
