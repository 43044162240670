.container {
  position: absolute;
  right: 287px;
  top: -33px;
  border-radius: 24px;
}

.itemName {
  margin-top: 24px;
}

.itemSub {
  margin-top: 4px;
}

.priceContainer {
  margin-top: 24px;
}

.priceContainer {
  margin-top: 12px;
  gap: 9px;
}

.divider {
  width: 231px;
  margin: 16px auto 14px 10px;
}

.photo {
  width: 248px;
  height: 252px;
  border-radius: 14px;
  object-fit: cover;
}
