.container {
  gap: 8px;
}

.button {
  border: none;
  cursor: pointer;
  width: 62px;
  border-radius: 14px;
  background: #2a3152;
}

.button.active {
  background: #7b4cff;
}

.countBlock {
  border-radius: 14px;
  border: 1px solid #3b4267;
  width: 129px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count {
  height: 16px;
}

.inputContainer {
  padding: 0 !important;
  height: 48px !important;
}
