.card {
  border-radius: 30px;
  background: var(--purple-very-dark-color);
  padding: 20px 32px 20px 20px;
  min-width: 780px;
  gap: 24px;
}

.photoContainer {
  width: 228px;
  height: 228px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 228px;
  height: 228px;
  border-radius: 14px;
  object-fit: contain;
}

.textBlock {
  flex: 1;
}

.informationBlock {
  gap: 16px;
  width: 100%;
}

.divider {
  margin: 12px auto 0;
  width: calc(100% - 16px);
}

.buttonsContainer {
  margin-top: 16px;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.catalogButtonsBox {
  flex: 1;
  gap: 16px;
}

.catalogButtonsBox.vipDisable {
  margin-top: 16px;
}

.button {
  flex: 1;
}

.traderInfoBlock {
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--purple-color);
}

.traderTextBlock {
  gap: 4px;
}

.userStatusText.offline {
  color: #f85a5a !important;
}
.userStatusText.online {
  color: #ffc700 !important;
}
.userStatusText.game {
  color: #8ef84f !important;
}

.infoButtonsBox {
  gap: 16px;
}

.copyPhoneButton {
  background: var(--green-dark-color);
  border-radius: 14px;
  gap: 8px;
  padding: 0 12px;
  align-items: center;
  height: 40px;
}

.copyIcon {
  width: 16px;
  height: 16px;
}

.call {
  background: #202d34;
}

.chat {
  background: #1e2c43;
}
