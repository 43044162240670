.hide {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
}

.show {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease-in;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('../src/images/theme.png');
  background-repeat: no-repeat;
  background-position: top;
  background-color: #0e0e26;
}

.app.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(180deg, rgba(10, 9, 32, 0.82) 0%, #0e0e26 7.48%),
    url('./mobileComponents/pages/HomeMobile/welcome-back.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
