@font-face {
  font-family: 'Firs Neue';
  src:
    url('/src/fonts/TTFirsNeue-Medium.woff') format('woff'),
    url('/src/fonts/TTFirsNeue-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Flex';
  src: url('/src/fonts/RobotoFlex-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
