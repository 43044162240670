.container {
  flex: 1;
  gap: 42px;
}

.header {
  gap: 16px;
}

.icon {
  width: 40px;
  height: 40px;
}

.divider {
  width: 98%;
  margin: 0 8px;
}
