.container {
  border-radius: 30px;
  background-color: #141631;
  padding: 48px;
}

.errorText {
  font-size: 32px;
  margin-top: 24px;
}

.text {
  color: #7e88bb;
}
