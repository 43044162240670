.section {
  overflow: hidden;
  width: 1120px;
  margin: 40px auto 84px;
  position: relative;
}

.chat {
  border-radius: 30px 30px 0 0;
  background: #141631;
  padding: 24px;
}

.companionBlock {
  padding: 8px;
  margin-bottom: 8px;
}

.itemBlock {
  border-radius: 16px;
  background: #1a1d3c;
  padding: 8px;
}

.divider {
  margin: 16px 8px 0;
  height: 1px;
}
