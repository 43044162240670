.container {
  position: relative;
  margin-top: 168px;
  z-index: 1;
}

.header {
  display: flex;
  justify-content: center;
  gap: 29px;
}

.headerTextContainer {
  width: 960px;
}

.headerText {
  font-size: 48px;
  line-height: 72px;
}

.headerSub {
  line-height: 72px;
}

.filtersContainer {
  margin: 104px auto 0 auto;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  width: 990px;
}
