.button {
  gap: 16px;
  border-radius: 14px;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  background: transparent;
}

.button.active {
  background: #1a1d3c;
}

.icon {
  width: 20px;
  height: 20px;
}
