.container {
  border-radius: 30px;
  background: #141631;
  padding: 16px;
  gap: 16px;
  display: flex;
  position: relative;
}

.container:hover {
  cursor: pointer;
  background: #1a1d3c;
}

.avatar {
  width: 120px;
  height: 120px;
}

.body {
  flex: 1;
  justify-content: space-between;
}

.headerBlock {
  justify-content: space-between;
}

.newMessage {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 30px 0px 60px 30px;
  background: #7b4cff;
  padding: 2px 16px 2px;
  box-shadow: 0px 0px 12px #7b4cff;
}
